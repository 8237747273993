<template>
  <div class="result" v-loading="loadData">
    <div class="title">
      <div class="titleText">
        {{ resultObj.objLabel }}
        <!-- 查重合并 -->
        {{
          resultObj.obj == "Opportunity"
            ? $t("label.same.analyze")
            : $t("label.mergeRechecking")
        }}
      </div>
      <!-- title 无重复数据 -->
      <div class="resultText">
        <button v-if="hasRepeat" @click="goBack('one')">
          <!-- 重新设置查询条件 -->
          {{ $t("label.mr.title.button.back") }}
        </button>
        <button
          v-if="!hasRepeat"
          @click="goBack('two')"
          style="min-width: 140px"
        >
          <!-- 重新设置查询条件 -->
          返回视图
        </button>
        <span>
          <!-- 在 -->
          {{ $t("label.mr.descreption.reDataList1") }}
          {{ resultObj.totalRecord }}
          <!-- 条记录中共查询到满足条件的重复数据 -->
          {{ $t("label.mr.descreption.reDataList2") }}
          {{ resultObj.groupsnum }}
          <!-- 组 -->
          {{ $t("label.mr.descreption.oneGroupReData2") }}
        </span>
      </div>
    </div>
    <!-- 结果区域 -->
    <!-- 没有可合并数据 -->
    <div class="noRepeat" v-if="!hasRepeat">
      <div style="text-align: center">
        <div class="imgStyle">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-empty-search"></use>
          </svg>
        </div>
        <div style="margin-top: 20px; font-size: 16px; color: #333333">
          <!-- 无重复数据 -->
          {{ $t("label.mr.descreption.nocondition") }}
        </div>
      </div>
    </div>
    <!-- 有可合并数据 -->
    <div v-if="hasRepeat" class="hasRepeat">
      <!-- 重复生成多组数据 -->
      <div class="repeatData" v-for="(item, index) in repeatData" :key="index">
        <!-- 重复生成数据的标题 -->
        <div class="repeatDataTitle">
          <span>
            <!-- 第 -->
            {{ $t("label.mr.title.button.group1") }}
            {{ index + 1 }}
            <!-- 组重复数据，共有 -->
            {{ $t("label.mr.title.button.group2") }}
            {{ item.length }}
            <!-- 条记录 -->
            {{ $t("label.reportrecords") }}
          </span>
          <span
            @click="handelRepeat(index + 1)"
            style="color: #006dcc; margin-left: 20px; cursor: pointer"
          >
            <!-- 选择数据进行合并 -->
            {{ $t("vue_label_checkduplicate_select_data_to_merge") }}
          </span>
        </div>
        <!-- 重复的数据 -->
        <div class="repeatDataArea">
          <table>
            <!-- 表头 -->
            <tr class="dataTitle">
              <td
                v-for="(Sitem, Sindex) in resultObj.selectedFieldList"
                :key="Sindex"
              >
                {{ Sitem.labelName }}
              </td>
            </tr>
            <!-- 数据 -->
            <tr
              v-for="(Titem, Tindex) in item"
              :key="Tindex"
              style="height: 40px"
            >
              <td v-for="(Fitem, Findex) in Titem" :key="Findex">
                {{ Fitem }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getReDataList } from "./api.js";
export default {
  data() {
    return {
      //总的记录条数
      recordCount: 28,
      //重复数据组数
      repeatCount: 0,
      //是否有重复数据
      hasRepeat: true,
      // 总记录数
      totalRecord: 1000,
      // 重复的组数
      // groupsnum: 10,
      obj: this.$route.params,
      resultObj: {},
      // 保存表头字段
      tableField: [],
      repeatData: [],
      objectApi: this.$route.params.objApi,
      loadData: false,
    };
  },
  created() {},
  mounted() {
    this.loadData = true;
    this.reDataList();
  },
  methods: {
    goBack(process) {
      if (process == "one") {
        this.$router.go(-1);
      } else if (process == "two") {
        this.$router.go(-1);
        this.$router.go(-1);
      }
    },
    // 获取重复信息
    async reDataList() {
      let obj = sessionStorage.getItem("cnkiFirst");
      let result = await getReDataList(obj);
      this.loadData = false;
      this.resultObj = result.data;
      this.tableField = this.resultObj.selectedFieldList;
      // 将重复数据放入数组
      var arr = [];
      for (var key in this.resultObj.fieldName_m) {
        for (var index in this.resultObj.fieldName_m[key]) {
          arr.push(this.resultObj.fieldName_m[key][index]);
        }
      }
      this.tableField.map((item) => {
        if (item.schemefieldType == "Y" || item.schemefieldType == "R") {
          item.schemefieldName += "ccname";
        }
      });
      // 遍历数据列表  追加两个空数据
      arr.map((item) => {
        let dataArray = [];
        // 遍历item 为空数组追加对象
        item.map((Sitem) => {
          let dataObject = {};
          // 遍历字段信息  如果有则为对象添加数据
          this.tableField.map((Titem) => {
            dataObject[Titem.schemefieldName] = Sitem[Titem.schemefieldName];
          });
          dataArray.push(dataObject);
        });
        this.repeatData.push(dataArray);
      });
      if (this.resultObj.groupsnum == "0") {
        this.hasRepeat = false;
      } else {
        this.hasRepeat = true;
      }
    },
    handelRepeat(index) {
      let object = JSON.parse(sessionStorage.getItem("cnkiFirst"));
      object.isCurGroup = "true";
      object.curGroup = index;
      object.objectAPI = this.objectApi;
      object.totalCount = this.resultObj.groupsnum;
      object.processedCount = "0";
      object.reDataStr = JSON.stringify(this.resultObj.fieldName_m);

      object = JSON.stringify(object);
      sessionStorage.setItem("cnkiData", object);
      this.$router.push({
        path: `/commonObjects/handleRepeat`,
        query: {
          groupsnum: this.resultObj.groupsnum,
          index,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.result {
  margin: 10px;
  border-radius: 4px;
}
.title {
  padding: 23px 0px 23px 23px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
}
.titleText {
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  margin-bottom: 15px;
  font-weight: 550;
}
.resultText button {
  background: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 3px;
  /* width: 180px; */
  height: 32px;
  margin-right: 10px;
  outline: none;
  padding: 0px 10px;
}
.noRepeat {
  margin-top: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  height: 645px;
}
.hasRepeat {
  margin-top: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.repeatData {
  padding: 24px 24px 24px 24px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  background-color: #ffffff;
}
.dataTitle {
  border-bottom: 1px solid #ebebeb;
}
.repeatDataTitle {
  font-size: 15px;
  color: #333333;
  font-weight: 500;
}
.repeatDataArea {
  margin-top: 20px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #333333;
}
table {
  width: 100%;
}
td {
  min-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.imgStyle {
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .icon {
  width: 420px;
  height: 232px;
}
</style>
